const errorMessages = {
  CONDITION_OP1_MUST_NOT_BE_DELETED:
    'A kérdés nem törölhető, mert egy feltétel hivatkozik rá',
  CONDITION_OP1_QUESTION_ORDER_NOT_LESS_THAN_QUESTION_ORDER:
    'A kérdés, amihez a feltétel tartozik, nem lehet előrébb, mint a feltételben szerepől kérdés',
  CONDITION_GROUP_NO_NOT_CONSECUTIVE:
    'A feltétel csoportok sorszáma nem folytonos, egy feltétel csoport nem található',
  CONDITION_SEQUENCE_NO_REQUIRED: 'A feltételhez nincs megadva sorrend',
  CONDITION_SEQUENCE_NO_NOT_UNIQUE: 'A feltétel sorrendje nem egyedi',
  CONDITION_OP1_QUESTION_ID_REQUIRED: 'A feltételhez nincs megadva kérdés',
  CONDITION_OP1_QUESTION_NOT_FOUND:
    'A feltételhez megadott kérdés nem található',
  CONDITION_OP1_SUBQUESTION_ID_REQUIRED: 'A feltételhez nincs megadva alkérdés',
  CONDITION_OP1_SUBQUESTION_NOT_FOUND:
    'A feltételhez megadott alkérdés nem található',
  CONDITION_OPERATOR_NOT_VALID: 'A feltételhez megadott operátor nem megfelelő',
  CONDITION_OP2_ANSWER_ID_REQUIRED: 'A feltételhez nincs megadva válasz',
  CONDITION_OP2_ANSWER_ID_NOT_IN_OP1_ANSWERS:
    'A feltételhez megadott válasz nem található a megadott kérdés válaszai között',
  CONDITION_OP2_VALUE_NOT_VALID: 'A feltételhez megadott érték nem megfelelő',
  ANSWER_IS_USED_IN_A_CONDITION:
    'A válasz nem törölhető, mert egy feltétel hivatkozik rá',
  SUB_QUESTION_IS_USED_IN_A_CONDITION:
    'Az alkérdés nem törölhető, mert egy feltétel hivatkozik rá',
};

export default errorMessages;
