import React from 'react';
import {
  Typography,
  Divider,
  Progress,
  Statistic,
  Button,
  Popconfirm,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
  ShoppingOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import './question-info-card.style.css';

const { Title, Text } = Typography;

const QuestionInfoCard = ({
  question = {
    answers: [],
  },
  questionList = [],
  onEditClick = () => {},
  onDeleteClick = () => {},
  onOrderClick = () => {},
  onJumpClick = () => {},
  onSurveyCloseClick = () => {},
  onSurveyCloseForSubQuestionClick = () => {},
}) => {
  const getSubAnswerPercentage = (answer, subQuestion) => {
    const answerCounter = answer.voteCountsBySubQuestion[subQuestion.id] ?? 0;

    const percentage =
      answerCounter === 0
        ? 0
        : ((answerCounter / subQuestion.voteCount) * 100).toFixed(0);
    return percentage + '%';
  };

  const getSubQuestionSkippedPercentage = subQuestion => {
    const subQuestionCounter = subQuestion.voteCount;

    const answeredCounter = question.answers.reduce((total, answer) => {
      return total + (answer.voteCountsBySubQuestion[subQuestion.id] ?? 0);
    }, 0);

    const skippedCounter = subQuestionCounter - answeredCounter;

    const percentage =
      skippedCounter === 0
        ? 0
        : ((skippedCounter / subQuestionCounter) * 100).toFixed(0);

    return percentage + '%';
  };

  const getSubAnswerIsJumpToEnd = (answer, subQuestion) => {
    if (!subQuestion.answerMetas || subQuestion.answerMetas.length === 0) {
      return false;
    }

    const answerMeta = subQuestion.answerMetas.find(
      x => x.survey_answer_id === answer.id
    );

    console.log(subQuestion.answerMetas);
    console.log(answer.id);
    console.log(answerMeta);

    if (!answerMeta) {
      return false;
    }

    return answerMeta.jumpToEnd;
  };

  return (
    <div className="question-info-card" data-testid="question-info-card">
      <div className="question-info-card__content">
        <div className="question-info-card_text">
          <div className="question-info-card_title">
            <Title level={4}>
              {question.content && question.content.replace('\n', '')}
            </Title>
          </div>
          <div className="question-info-card_description">
            <div className="question-info-card_vote">
              {question.type === 'table' && (
                <>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th></th>
                        {question.answers.map(answer => (
                          <th key={answer.id} title={answer.content}>
                            <span>{answer.content}</span>
                          </th>
                        ))}
                        {question.subQuestionSkippable && (
                          <th>{question.skipText}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {question.subQuestions.map(subQuestion => (
                        <tr key={subQuestion.id}>
                          <td>{subQuestion.content}</td>
                          {question.answers.map(answer => (
                            <td key={answer.id}>
                              <div className="table-buttons">
                                {getSubAnswerPercentage(answer, subQuestion)}
                                <Button
                                  style={{ marginLeft: '5px' }}
                                  danger
                                  type="primary"
                                  icon={<CloseOutlined />}
                                  onClick={() =>
                                    onSurveyCloseForSubQuestionClick({
                                      question,
                                      answerId: answer.id,
                                      subQuestionId: subQuestion.id,
                                      answerUUID: answer.uuid,
                                    })
                                  }
                                  size="small"
                                />
                              </div>
                              <div className="table-meta-info">
                                {getSubAnswerIsJumpToEnd(
                                  answer,
                                  subQuestion
                                ) && <Text>Kérdőív lezárása</Text>}
                              </div>
                            </td>
                          ))}
                          {!!question.subQuestionSkippable && (
                            <td>
                              {getSubQuestionSkippedPercentage(subQuestion)}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {question.type !== 'table' &&
                question.answers
                  .sort((a, b) => a.order - b.order)
                  .map(answer => (
                    <div key={answer.id}>
                      <div>
                        <Text>{answer.content}</Text>
                        {question.type !== 'ordered' && (
                          <>
                            <Button
                              danger
                              type="primary"
                              style={{ marginLeft: '10px' }}
                              icon={<CloseOutlined />}
                              onClick={() =>
                                onSurveyCloseClick({
                                  answer,
                                })
                              }
                              size="small"
                            />
                            <Button
                              style={{ marginLeft: '5px' }}
                              icon={<ArrowRightOutlined />}
                              onClick={() =>
                                onJumpClick({
                                  answer,
                                })
                              }
                              size="small"
                            />
                            {answer.next_question_id && (
                              <Text style={{ marginLeft: '10px' }}>
                                {
                                  questionList.find(
                                    question =>
                                      question.id === answer.next_question_id
                                  ).content
                                }
                              </Text>
                            )}
                            {answer.jumpToEnd && (
                              <Text style={{ marginLeft: '10px' }}>
                                Kérdőív lezárása
                              </Text>
                            )}
                          </>
                        )}
                      </div>
                      {question.type !== 'ordered' && (
                        <Progress
                          percent={
                            answer.counter === 0
                              ? 0
                              : (
                                  (answer.counter / question.counter) *
                                  100
                                ).toFixed(0)
                          }
                        />
                      )}
                    </div>
                  ))}
            </div>
            <div className="question-info-card_stat">
              <Statistic
                title="Válaszadók száma"
                value={question.counter}
                prefix={<UserOutlined />}
              />
              <Statistic
                title="Pont érték"
                value={question.point}
                prefix={<ShoppingOutlined />}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="question-info-card_footer">
        <Button icon={<EditOutlined />} onClick={() => onEditClick(question)} />
        <Divider type="vertical" />
        <Button
          icon={<UpOutlined />}
          onClick={() => onOrderClick(question, 'up')}
        />
        <Divider type="vertical" />
        <Button
          icon={<DownOutlined />}
          onClick={() => onOrderClick(question, 'down')}
        />
        <Divider type="vertical" />
        <Popconfirm
          title="Biztos a törlésben"
          onConfirm={() => onDeleteClick(question)}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Button type="danger" icon={<DeleteOutlined />} />
        </Popconfirm>
      </div>
    </div>
  );
};

export default QuestionInfoCard;
